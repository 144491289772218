<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" :title="fromTitle" @close="onClose">
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="微信应用类型" prop="wxType" >
        <a-select placeholder="请选择微信应用类型" v-model="form.wxType">
          <a-select-option v-for="(d, index) in wxTypeOptions" :key="index" :value="d.dictValue" >{{ d.dictLabel }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="AppId" prop="wxAppid" >
        <a-input v-model="form.wxAppid" placeholder="请输入AppId" />
      </a-form-model-item>
      <a-form-model-item label="Secret" prop="wxSecret" >
        <a-input v-model="form.wxSecret" placeholder="请输入Secret" />
      </a-form-model-item>
      <a-form-model-item label="消息服务器token" prop="wxMsgToken" >
        <a-input v-model="form.wxMsgToken" placeholder="请输入消息服务器token" />
      </a-form-model-item>
      <a-form-model-item label="消息服务器EncodingAESKey" prop="wxAesKey" >
        <a-input v-model="form.wxAesKey" placeholder="请输入消息服务器EncodingAESKey" />
      </a-form-model-item>
      <a-form-model-item label="所属租户ID" prop="tenantId" >
        <a-input v-model="form.tenantId" placeholder="请输入所属租户ID" />
      </a-form-model-item>
      <a-form-model-item label="是否有效" prop="status" v-if="formType == 2">
        <a-radio-group v-model="form.status" button-style="solid">
          <a-radio-button v-for="(d, index) in statusOptions" :key="index" :value="d.dictValue">{{ d.dictLabel }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getWechatConfig, addWechatConfig, updateWechatConfig } from '@/api/iot/wechatConfig'

export default {
  name: 'CreateForm',
  props: {
    wxTypeOptions: {
      type: Array,
      required: true
    },
    statusOptions: {
      type: Array,
      required: true
    }
  },
  components: {
  },
  data () {
    return {
      loading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        wxType: null,
        wxAppid: null,
        wxSecret: null,
        wxMsgToken: null,
        wxAesKey: null,
        tenantId: null,
        status: 0,
        createBy: null,
        createTime: null,
        updateBy: null,
        updateTime: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        wxType: [
          { required: true, message: '微信应用类型不能为空', trigger: 'change' }
        ],
        wxAppid: [
          { required: true, message: 'AppId不能为空', trigger: 'blur' }
        ],
        wxSecret: [
          { required: true, message: 'Secret不能为空', trigger: 'blur' }
        ],
        wxMsgToken: [
          { required: true, message: '消息服务器token不能为空', trigger: 'blur' }
        ],
        wxAesKey: [
          { required: true, message: '消息服务器EncodingAESKey不能为空', trigger: 'blur' }
        ],
        tenantId: [
          { required: true, message: '所属租户ID不能为空', trigger: 'blur' }
        ],
        status: [
          { required: true, message: '是否有效', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        wxType: null,
        wxAppid: null,
        wxSecret: null,
        wxMsgToken: null,
        wxAesKey: null,
        tenantId: null,
        status: 0,
        createBy: null,
        createTime: null,
        updateBy: null,
        updateTime: null
      }
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getWechatConfig(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.id !== undefined && this.form.id !== null) {
            updateWechatConfig(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            })
          } else {
            this.form.status = 1
            addWechatConfig(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
