import request from '@/utils/request'

// 查询 微信配置列表
export function listWechatConfig (query) {
  return request({
    url: '/iot/wechatConfig/list',
    method: 'get',
    params: query
  })
}

// 查询 微信配置详细
export function getWechatConfig (id) {
  return request({
    url: '/iot/wechatConfig/' + id,
    method: 'get'
  })
}

// 新增 微信配置
export function addWechatConfig (data) {
  return request({
    url: '/iot/wechatConfig',
    method: 'post',
    data: data
  })
}

// 修改 微信配置
export function updateWechatConfig (data) {
  return request({
    url: '/iot/wechatConfig',
    method: 'put',
    data: data
  })
}

// 删除 微信配置
export function delWechatConfig (id) {
  return request({
    url: '/iot/wechatConfig/' + id,
    method: 'delete'
  })
}

// 导出 微信配置
export function exportWechatConfig (query) {
  return request({
    url: '/iot/wechatConfig/export',
    method: 'get',
    params: query
  })
}
